<template>
  <validation-observer
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="submit()"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Endereço
          </h5>
          <small class="text-muted">Entre com o seu endereço</small>
        </b-col>
        <!-- Form start -->
        <b-form
          class="col-md-12 mb-2"
          @submit.prevent="submit()"
        >
          <b-col md="12">
            <b-row>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="zipcode"
                  rules="required|numeric"
                >
                  <b-form-group
                    label="CEP"
                    label-for="zipcode"
                  >
                    <b-form-input
                      id="zipcode"
                      v-model="address.zipcode"
                      placeholder="57254000"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="number"
                  rules="required"
                >
                  <b-form-group
                    label="Nº"
                    label-for="number"
                  >
                    <b-form-input
                      id="number"
                      v-model="address.number"
                      placeholder="520"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="street"
                  rules="required"
                >
                  <b-form-group
                    label="Rua"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      v-model="address.street"
                      placeholder="Rua da paz"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="neighborhood"
                  rules="required"
                >
                  <b-form-group
                    label="Bairro"
                    label-for="neighborhood"
                  >
                    <b-form-input
                      id="neighborhood"
                      v-model="address.neighborhood"
                      placeholder="Martins"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="city"
                  rules="required"
                >
                  <b-form-group
                    label-for="city"
                    label="Cidade"
                  >
                    <b-form-input
                      id="city"
                      v-model="address.city"
                      placeholder="São Paulo"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="state"
                  rules="required"
                >
                  <b-form-group
                    label-for="state"
                    label="Estado"
                  >
                    <b-form-input
                      id="state"
                      v-model="address.state"
                      placeholder="São Paulo"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-form>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('previous-step')"
      >
        Voltar
      </b-button>

      <b-button
        variant="primary"
        class="float-right"
        type="submit"
      >
        Continuar
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BForm
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { mapActions } from 'vuex'
import {
  alpha, alpha_spaces, numeric
} from 'vee-validate/dist/rules'
import PaymentServices from './paymentServices'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('alpha', {
  ...alpha,
  message: 'Apenas letras nesse campo.',
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Apenas letras e números nesse campo.',
})

extend('numeric', {
  ...numeric,
  message: 'Apenas números nesse campo.',
})

export default {
  components: {
    BRow,
    BCol,
    BForm,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      address: {
        id: '',
        street: '',
        number: '',
        neighborhood: '',
        zipcode: '',
        city: '',
        state: ''
      }
    }
  },
  computed: {
    zipCode() {
      return this.address.zipcode
    },
    addressState() {
      return this.$store.state.payment.address
    },
    userState() {
      return this.$store.state.auth.user
    },
  },
  watch: {
    zipCode() {
      if (this.zipCode) {
        if (this.zipCode.length === 8 && (!this.addressState.zipcode || this.addressState.zipcode === '')) {
          this.search_cep()
        }
      }
    },
  },
  async mounted() {
    if (!this.addressState.zipcode || this.addressState.zipcode === '') {
      if (this.userState.address) {
        await this.ActionSetAddress({
          street: this.userState.address.street,
          number: this.userState.address.number,
          neighborhood: this.userState.address.neighborhood,
          zipcode: this.userState.address.cep,
          city: this.userState.address.city,
          state: this.userState.address.state,
        })
      }
    }

    this.address.street = this.addressState.street
    this.address.number = this.addressState.number
    this.address.neighborhood = this.addressState.neighborhood
    this.address.zipcode = this.addressState.zipcode
    this.address.city = this.addressState.city
    this.address.state = this.addressState.state
  },
  methods: {
    ...mapActions('payment', [
      'ActionSetAddress'
    ]),
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          await this.ActionSetAddress({
            street: this.address.street,
            number: this.address.number,
            neighborhood: this.address.neighborhood,
            zipcode: this.address.zipcode,
            city: this.address.city,
            state: this.address.state,
          })

          this.$emit('next-step')
        }
      })
    },
    search_cep(event) {
      if (event) {
        event.preventDefault()
      }
      const url_cep = `https://viacep.com.br/ws/${this.zipCode}/json`

      // clear all headers axios to viacep
      axios.defaults.headers.common = null

      axios
        .get(url_cep)
        .then((response) => {
          if (response.data.erro === true) {
            this.address.zipcode = this.zipCode
            return
          }
          this.address.street = response.data.logradouro
          this.address.neighborhood = response.data.bairro
          this.address.state = response.data.uf
          this.address.city = response.data.localidade
          this.address.cep = response.data.cep
        })
        .catch((error) => {
          console.log(error.statusText)
        })
    },
  }
}
</script>

<style scoped>
button.btn-next {
  display: none !important;
  color: #000 !important;

}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
